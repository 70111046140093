@import 'bp';
@import 'fonts';
@import 'palette';
@import 'spacing';

$top-header-offset: 60px;

$dashboard-content-width: 480px;

.tray {
    align-items: flex-start;
    background-color: $dark-color-alpha;
    color: $light-color;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    overflow-y: auto;
    position: fixed;
    right: -100vw;
    text-align: center;
    width: 100%;
    z-index: 200;
}

.tray.active {
    position: absolute;
    right: 0;
    top: 0;
}

.contain {
    width: 270px;
}

.content {
    background-color: $brand-color;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-y: auto;
    width: 100%;

    @media (min-width: 640px) {
        max-width: $dashboard-content-width;
    }
}

.close {
    color: $light-color-2;
    cursor: pointer;
    font-size: $fs-4;
    padding: 0;
    position: absolute;
    right: $spacing-3;
    top: $spacing-3;

    @media(min-width: 640px) {
        right: $spacing-6;
        top: $spacing-6;
    }
}
