/* border-radius */
$br--7: 0.5px;
$br--6: 2px;
$br--4: 3px;
$br--3: 10px;
$br-0: 16px;
$br-1: 8px;
$br-3: 20px;
$br-4: 30px;

/* border-width */

$bw-0: 1px;
$bw-1: 2px;
$bw-2: 3px;
$bw-3: 4px;
